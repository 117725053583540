import Home from "./Home";
import Services from "./Services";
import Stores from "./Stores";
import Coaching from "./Coaching";
import FAQs from "./FAQ";
import Footer from "./Footer";
import Banner from "./Banner";
import Coupon from "./Coupon";
import ScrollToTop from "react-scroll-to-top";

function App() {
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", gap: "4rem" }}>
        <Home />
        <Banner />
        <Services />
        <Stores />
        <Coaching />
        <FAQs />
        <Footer />
        <ScrollToTop
          color="#624185"
          style={{
            bottom: "0.7rem",
            right: "0.7rem",
            backgroundColor: "#f0944f",
            opacity: "50%",
          }}
        />
      </div>
    </>
  );
}

export default App;
