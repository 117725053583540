import "./Stores.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Fade } from "react-reveal";
import Locations from "./Locations";

function Stores() {
  return (
    <div id="stores" className="stores-section">
      <Brands />
      <Locations />
      <video width="300" height="500" controls>
        <source src="/videos/bbi14.mp4" type="video/mp4" />
      </video>
    </div>
  );
}

function Brands() {
  return (
    <div className="brands-section">
      <h1 className="section-heading">Stores</h1>
      <h1 className="section-subheading">Proudly Supplied By</h1>
      <LogoContainer />
    </div>
  );
}

function LogoContainer() {
  return (
    <section className="logo-container">
      {logos.map((logo) => (
        <Fade bottom>
          <img src={logo.logo} alt="logo" className="logo" />
        </Fade>
      ))}
    </section>
  );
}

const logos = [
  { logo: "./images/1UPlogo.png" },
  { logo: "./images/allmaxlogo.png" },
  { logo: "./images/c4.png" },
  { logo: "./images/finaflexlogo.png" },
  { logo: "./images/MNlogo.png" },
  { logo: "./images/musclemedslogo.png" },
  { logo: "./images/musclesportlogo.png" },
  { logo: "./images/muscletechlogo.png" },
  { logo: "./images/nutrabolics.png" },
  { logo: "./images/PLlogo.png" },
  { logo: "./images/ryselogo.png" },
  { logo: "./images/universal-logo.png" },
  { logo: "./images/vmilogo.png" },
  { logo: "./images/obvilogo.png" },
  { logo: "./images/kaged.png" },
  { logo: "./images/bpi.png" },
  { logo: "./images/dymatize-logo.png" },
];

export default Stores;
