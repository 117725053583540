import { useState } from "react";
import "./Btn.css";

function Btn({
  className,
  width,
  text,
  backgroundColor,
  padding,
  fontSize,
  border,
  color,
  borderRadius,
  hoverBgColor,
  hoverColor,
  hoverBorder,
  transition,
  onClick,
}) {
  const [hover, setHover] = useState(false);

  function handleMouseEnter() {
    setHover(true);
  }

  function handleMouseLeave() {
    setHover(false);
  }

  return (
    <button
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
      style={{
        width: `${width}%`,
        backgroundColor: hover ? `${hoverBgColor}` : `${backgroundColor}`,
        padding: padding,
        fontSize: `${fontSize}rem`,
        border: hover ? hoverBorder : border,
        color: hover ? `${hoverColor}` : `${color}`,
        borderRadius: `${borderRadius}px`,
        transition: "all 0.3s ease-in-out",
        cursor: "pointer",
        transform: hover ? "scale(1.05)" : "",
      }}
      className={className}
    >
      {text}
    </button>
  );
}

export default Btn;
