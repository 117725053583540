import "./Locations.css";
import { Reveal } from "react-reveal";
import YoutubeEmbed from "./YoutubeEmbed";
import Btn from "./components/Btn";

function Locations() {
  //   function handleClick(e) {
  //     e.preventDefault();
  //     window.open(
  //       `http://www.google.com/maps/place/${location.position.lat},${location.position.lng}`,
  //       "_blank",
  //       "noreferrer"
  //     );
  //   }

  return (
    <>
      <h1 className="section-subheading">Locations</h1>
      <div className="location-container">
        {storeLocations.map((location) => (
          <Reveal right>
            <div className="location-card">
              <h3 className="location-heading">
                <img
                  src="./images/location-pin.png"
                  className="location-pin"
                  alt="location pin"
                />{" "}
                {location.name}
              </h3>
              <h5 className="location-address">{location.address}</h5>
              <Btn
                className="hero-btn"
                width={65}
                text="Get directions"
                backgroundColor="transparent"
                padding="0.6rem 0.7rem 0.6rem 0.7rem"
                fontSize={1}
                border="solid 1px var(--orange-color)"
                color="var(--offwhite-color)"
                borderRadius={30}
                hoverBgColor="var(--orange-color)"
                hoverBorder="none"
                hoverColor="black"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    `http://www.google.com/maps/place/${location.position.lat},${location.position.lng}`,
                    "_blank",
                    "noreferrer"
                  );
                }}
              ></Btn>
            </div>
          </Reveal>
        ))}
      </div>
    </>
  );

  //   <button
  //     className="info-btn"
  //     onClick={(e) => {
  //       e.preventDefault();
  //       window.location.href = `http://www.google.com/maps/place/${position.lat},${position.lng}`;
  //     }}
  //   >
  //     Open in Google Maps
  //   </button>
}

const storeLocations = [
  {
    id: 1,
    name: "Port-of-Spain, Trinidad",
    position: { lat: 10.660458231800227, lng: -61.52032911054306 },
    address: "20 French Street, Port-of-Spain",
  },
  {
    id: 2,
    name: "St. Augustine, Trinidad",
    position: { lat: 10.645522810072242, lng: -61.39673130649152 },
    address: "139 Eastern Main Road, St. Augustine",
  },
  {
    id: 3,
    name: "Chaguanas, Trinidad",
    position: { lat: 10.502476841609935, lng: -61.40757346992763 },
    address: "86 Edinburgh Village, Chaguanas",
  },
  {
    id: 4,
    name: "San-Fernando, Trinidad",
    position: { lat: 10.25763083793674, lng: -61.476072116520925 },
    address: "Corner Bamboo, Gulf View Drive, San Fernando",
  },

  {
    id: 5,
    name: "Sangre Grande, Trinidad",
    position: { lat: 10.586123599177805, lng: -61.13565960456068 },
    address: "16 Eastern Main Road, Sangre Grande",
  },
  {
    id: 6,
    name: "Access Point, Debe, Trinidad",
    position: { lat: 10.188086041421881, lng: -61.45035806038518 },
    address: "1124 S.S. Erin Rd, Debe",
  },
  {
    id: 7,
    name: "Bon Accord, Tobago",
    position: { lat: 11.157533798977582, lng: -60.81662787066045 },
    address: "Milford Road, Canaan",
  },
];

export default Locations;
