import "./Banner.css";
import Btn from "./components/Btn";
import Marquee from "react-fast-marquee";

function Banner() {
  return (
    <>
      <Marquee
        className="marquee"
        pauseOnHover="true"
        style={{
          backgroundColor: "#f0944f",
          width: "99%",
          color: "black",

          fontSize: "1.5rem",
          overflowX: "hidden",
        }}
      >
        <p>
          {" "}
          Be on the look out for the <strong>
            Biggest Loser Challenge
          </strong>{" "}
          2024. Sign up NOW via consultations!{" "}
        </p>
      </Marquee>
      <Btn
        className="sign-up-btn"
        width={50}
        text="Sign Up"
        backgroundColor="transparent"
        padding="1rem 0.7rem 1rem 0.7rem"
        fontSize={1}
        border="solid 1px var(--white-color)"
        color="var(--offwhite-color)"
        borderRadius={30}
        hoverBgColor="var(--orange-color)"
        hoverBorder="none"
        hoverColor="black"
        onClick={(e) => {
          window.location.href = "mailto:bodybyimranconsultations@gmail.com";
        }}
      ></Btn>
      <div className="notice notice-marquee">
        <span>*Notice:</span> Please read FAQs before sending consultation
        emails OR before signing up for the Biggest Loser Challenge.
      </div>
    </>
  );
}

export default Banner;
