import { useState, useRef } from "react";
import "./FAQ.css";
import Reveal from "react-reveal";

function Faqs() {
  return (
    <div id="faqs" className="faqs-section">
      <h1 className="section-heading">Frequently Asked Questions</h1>
      <FaqsAccordion accordionInfo={accordionInfo} />
    </div>
  );
}

function FaqsAccordion({ accordionInfo }) {
  return (
    <div className="accordion-wrapper">
      {accordionInfo.map((info) => (
        <AccordionItem info={info} />
      ))}
    </div>
  );
}

function AccordionItem({ info }) {
  const [openItem, setOpenItem] = useState(false);

  const ref = useRef(null);

  function handleAccordionClick() {
    setOpenItem(!openItem);
  }

  return (
    <div className="accordion-item">
      <h4 className="accordion-question" onClick={handleAccordionClick}>
        {info.question}{" "}
        <span
          className="arrow"
          style={openItem ? { transform: "rotate(180deg)" } : {}}
        >
          ↓
        </span>
      </h4>
      <Reveal>
        <div
          ref={ref}
          className="accordion-answer"
          style={openItem ? { display: "block" } : { display: "none" }}
        >
          <p>{info.answer}</p>
        </div>
      </Reveal>
    </div>
  );
}

const accordionInfo = [
  {
    id: 1,
    question: "What is required for consultations? ",
    answer:
      "Before consultations for personal training or online coaching, an email must be sent to our email address (bodybyimranconsultations@gmail.com) stating full name, contact number, age, current weight, goal weight, height, any risk factors (please state specifically if you are currently a cardiac patient) and/or any injuries. You will be contacted within 3-5 business days to confirm a date for your consultation.",
  },
  {
    id: 2,
    question: "Where are you situated?",
    answer:
      "Our website shows all of our 7 locations on the Locations page. Directions can be accessed through Google Maps by hitting the `Get Directions` button",
  },
  {
    id: 3,
    question: "What are your opening hours?",
    answer:
      "We are opened Monday to Saturday, 9:30am - 5:00pm. We are not opened on public holidays unless specified on our social media (usually Facebook or Instagram).",
  },
  {
    id: 4,
    question: "What can I take to lose/gain weight?",
    answer:
      "We are sorry to inform that advice cannot be given over the phone, as the individual must be seen in person in order to be assessed. A consultation can be booked, or you can head over to any of our 7 locations where our professionals would be happy to advise!",
  },
  {
    id: 5,
    question: "What are your return policies?",
    answer:
      "Any supplement can be returned to our store WITHIN 24 HOURS (with receipt). The seal of the supplement MUST BE IN TACT and the supplement must not have been damaged or tampered with i.e, the item must be in the state that it left the store.",
  },
];

export default Faqs;
