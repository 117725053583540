import "./Coaching.css";
import { Reveal } from "react-reveal";
import Btn from "./components/Btn";
import ReactImageGallery from "react-image-gallery";

function Coaching() {
  return (
    <div id="coaching" className="coaching-section">
      <OurCoaches coaches={coaches} />
    </div>
  );
}

function OurCoaches({ coaches }) {
  return (
    <>
      <h1 className="section-heading">Coaching</h1>
      <h1 className="section-subheading">Our Coach</h1>
      <div className="coaches-container">
        {coaches?.map((coach) => (
          <CoachCard coach={coach} />
        ))}
      </div>
      <h1 className="section-subheading">Transformations</h1>
      <ReactImageGallery
        showFullscreenButton="false"
        showPlayButton="false"
        items={images}
        className="image-gallery"
      />
      <div className="coaching-btn-wrapper">
        <Btn
          className="coaching-btn"
          width={100}
          text="Click to consult 🏋️‍♂️"
          backgroundColor="transparent"
          padding="1.5rem 0.5rem 1.5rem 0.5rem"
          fontSize={2}
          border="solid 0.5px var(--orange-color)"
          color="var(--offwhite-color)"
          borderRadius={30}
          hoverBgColor="var(--orange-color)"
          hoverBorder="none"
          hoverColor="black"
          onClick={(e) => {
            window.location.href = "mailto:bodybyimranconsultations@gmail.com";
          }}
        ></Btn>
      </div>
      <div className="notice notice-coaching">
        <span>*Notice:</span> Please read FAQs before sending consultation
        emails. A price for consultation is given upon setting a date. Depending
        on your location, you may be appointed one of our associate trainers.
      </div>
    </>
  );
}

function CoachCard({ coach }) {
  return (
    <Reveal>
      <div className="coach-card">
        <div className="coach-img-container">
          <img className="coach-img" src={coach.img} alt="coach" />
        </div>
        <h1 className="coach-name">{coach.name}</h1>
        <div className="coach-roles">
          {coach.roles.map((role) => (
            <p className="coach-role">{role}</p>
          ))}
        </div>
      </div>
    </Reveal>
  );
}

const images = [
  {
    original: "./images/transformation1.jpg",
    thumbnail: "./images/transformation1.jpg",
  },
  {
    original: "./images/transformation2.jpg",
    thumbnail: "./images/transformation2.jpg",
  },
  {
    original: "./images/transformation3.jpg",
    thumbnail: "./images/transformation3.jpg",
  },
  {
    original: "./images/transformation4.jpg",
    thumbnail: "./images/transformation4.jpg",
  },
  {
    original: "./images/transformation5.jpg",
    thumbnail: "./images/transformation5.jpg",
  },
  {
    original: "./images/transformation6.jpg",
    thumbnail: "./images/transformation6.jpg",
  },
  {
    original: "./images/transformation7.jpg",
    thumbnail: "./images/transformation7.jpg",
  },
  {
    original: "./images/transformation8.jpg",
    thumbnail: "./images/transformation8.jpg",
  },
  {
    original: "./images/transformation9.jpg",
    thumbnail: "./images/transformation9.jpg",
  },
  {
    original: "./images/transformation10.jpg",
    thumbnail: "./images/transformation10.jpg",
  },
  {
    original: "./images/transformation11.jpg",
    thumbnail: "./images/transformation11.jpg",
  },
  {
    original: "./images/transformation12.jpg",
    thumbnail: "./images/transformation12.jpg",
  },
  {
    original: "./images/transformation13.jpg",
    thumbnail: "./images/transformation13.jpg",
  },
  {
    original: "./images/transformation14.jpg",
    thumbnail: "./images/transformation14.jpg",
  },
  {
    original: "./images/transformation15.jpg",
    thumbnail: "./images/transformation15.jpg",
  },
];

const coaches = [
  {
    id: 1,
    img: "./images/bbi-coach.png",
    name: "Imran Razac",
    roles: [
      "Founder",
      "Lead Trainer",
      "Fitness Specialist",
      "Transformation Coach",
      "Supplement Expert",
    ],
  },
  // ß
];

export default Coaching;
