import "./Footer.css";
import { SocialIcon } from "react-social-icons";

function Footer() {
  return (
    <>
      <div className="footer">
        <img src="./images/bbi-logo.PNG" alt="logo" className="footer-logo" />
        <div className="footer-wrapper">
          <div className="footer-links">
            <h1 className="links-header">Quick links</h1>
            <div className="links">
              <a href="#home" className="link">
                Home
              </a>
              <a href="#services" className="link">
                Services
              </a>
              <a href="#stores" className="link">
                Stores
              </a>
              <a href="#coaching" className="link">
                Coaching
              </a>
              <a href="#faqs" className="link">
                FAQs
              </a>
            </div>
          </div>
          <div className="footer-links">
            <h1 className="links-header">Social links</h1>
            <div className="footer-social-links">
              <SocialIcon
                url="https://www.instagram.com/bodybyimran/?hl=en"
                target="_blank"
                className="social-link"
                bgColor="transparent"
                style={{ width: "80%" }}
              />

              <SocialIcon
                url="https://www.facebook.com/bodybyimransupplements"
                target="_blank"
                className="social-link"
                bgColor="transparent"
                style={{ width: "80%" }}
              />

              <SocialIcon
                url="https://www.tiktok.com/@bodybyimran?lang=en"
                target="_blank"
                bgColor="transparent"
                className="social-link"
                style={{ width: "80%" }}
              />
            </div>
          </div>
        </div>
        <p className="copyright">&#169; 2010-2024 BodybyImran</p>
      </div>
      <p className="outro">
        designed & developed by{" "}
        <a
          href="https://www.instagram.com/pixelprecxsion/?hl=en"
          target="_blank"
          rel="noreferrer"
        >
          pixelprecision
        </a>
        .
      </p>
    </>
  );
}

export default Footer;
