import "./Services.css";
import Btn from "./components/Btn";
import { Fade } from "react-reveal";

function Services() {
  return (
    <div id="services" className="service-page">
      <h1 className="section-heading">Services</h1>
      <CardsContainer cardsInfo={cardsInfo} />
    </div>
  );
}

function CardsContainer({ cardsInfo }) {
  return (
    <div className="cards-container">
      {cardsInfo.map((info) => (
        <Card info={info} />
      ))}
    </div>
  );
}

function Card({ info }) {
  return (
    <Fade right cascade>
      <div className="service-card">
        <img className="card-image" src={info.image} alt={info.alt} />
        <h1 className="card-title">{info.heading}</h1>
        <p className="card-description">{info.description}</p>
        <Btn
          className="service-btn"
          width={70}
          text={info.btn}
          backgroundColor="transparent"
          padding="0.8rem 0.5rem 0.8rem 0.5rem"
          fontSize={0.9}
          border="solid 1px var(--orange-color)"
          color="var(--offwhite-color)"
          borderRadius={30}
          hoverBgColor="var(--orange-color)"
          hoverBorder="none"
          hoverColor="black"
          onClick={(e) => (window.location.href = info.link)}
        ></Btn>
      </div>
    </Fade>
  );
}

const cardsInfo = [
  {
    id: 1,
    heading: "Personal Training",
    image: "./images/personaltraining.png",
    description:
      "Unlock your full potential with our personalized personal training services. Experience one-on-one fitness guidance, expert coaching, and tailored workout plans—provided by our dedicated professionals.",
    btn: "Inquire",
    link: "mailto:bodybyimranconsultations.com",
  },
  {
    id: 2,
    heading: "Supplement & Nutrition",
    image: "./images/supplements.png",
    description:
      "Discover a curated selection of high-quality products designed to support your fitness goals. Fuel your body, optimize performance, and embrace a vibrant lifestyle with our exceptional solutions.",
    btn: "Learn more",
    link: "#stores",
  },
  {
    id: 2,
    heading: "Consultations",
    image: "./images/consultations.png",
    description:
      "Transform your fitness journey with our personalized fitness consultations. Our expert will assess your goals, create a tailored plan, and provide guidance on workouts and nutrition.",
    btn: "Book consultation",
    link: "mailto:bodybyimranconsultations.com",
  },
];

export default Services;
