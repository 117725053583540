import "./Home.css";
import Navigation from "./components/Navigation";
import { Fade } from "react-awesome-reveal";
import Btn from "./components/Btn";

//Delete this okok

function Homepage() {
  return (
    <>
      <div id="home" className="home-page">
        <Navigation
          backgroundColor="#1f1f1f"
          imgSrc="./images/bbi-logo.PNG"
          hamburgerColor="#ffffff"
        />
        <Hero />
      </div>
    </>
  );
}

function Hero() {
  return (
    <div className="hero-section">
      <Fade cascade>
        <div className="hero-text">
          <h1 className="hero-heading">
            Health, Fitness, <span>Lifestyle.</span>
          </h1>
          <p className="hero-sub-heading">
            If we don't have it, you don't need it.
          </p>

          <Btn
            className="hero-btn"
            width={60}
            text="Book consultation"
            backgroundColor="transparent"
            padding="0.8rem 0.7rem 0.8rem 0.7rem"
            fontSize={1}
            border="solid 1px var(--white-color)"
            color="var(--offwhite-color)"
            borderRadius={30}
            hoverBgColor="var(--orange-color)"
            hoverBorder="none"
            hoverColor="black"
            onClick={(e) => {
              window.location.href =
                "mailto:bodybyimranconsultations@gmail.com";
            }}
          ></Btn>
        </div>
      </Fade>
      <div className="hero-image-container">
        <img
          className="hero-image-1"
          src="./images/hero-image.jpg"
          alt="hero 1"
        />
        <img className="hero-image-2" src="./images/both.jpg" alt="hero 2" />
      </div>
    </div>
  );
}

export default Homepage;
