import { useState, useEffect } from "react";
import "./Navigation.css";
import Hamburger from "hamburger-react";
import { Fade } from "react-awesome-reveal";

function Navigation({ backgroundColor, imgSrc, hamburgerColor }) {
  const [isOpen, setOpen] = useState(false);
  const [showNavBar, setShowNavBar] = useState(false);

  function handleShowNavBar() {
    setShowNavBar(!showNavBar);
    setOpen(!isOpen);
  }

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = isOpen ? "hidden" : "auto";
  }, [isOpen]);

  return (
    <div className="navigation">
      <div className="nav-header" style={{ backgroundColor: backgroundColor }}>
        <a href="#home">
          <img className="nav-logo" src={imgSrc} alt="logo" />
        </a>
        <div
          onClick={handleShowNavBar}
          className="hamburger"
          style={{ color: hamburgerColor }}
        >
          <Hamburger size={23} toggled={isOpen} toggle={setOpen} />
        </div>
      </div>
      {showNavBar && (
        <Fade delay={300} direction="top">
          <div className="nav-links-container">
            <Fade delay={200} cascade damping={0.2}>
              <ul className="nav-links">
                <p className="menu-name">Menu</p>
                <li className="nav-item">
                  <a
                    onClick={handleShowNavBar}
                    href="#home"
                    className="nav-link"
                  >
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    onClick={handleShowNavBar}
                    href="#services"
                    className="nav-link"
                  >
                    Services
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    onClick={handleShowNavBar}
                    href="#stores"
                    className="nav-link"
                  >
                    Stores
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    onClick={handleShowNavBar}
                    href="#coaching"
                    className="nav-link"
                  >
                    Coaching
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    onClick={handleShowNavBar}
                    href="#faqs"
                    className="nav-link"
                  >
                    FAQs
                  </a>
                </li>
              </ul>
            </Fade>
            <div className="social-links">
              <a
                onClick={handleShowNavBar}
                className="social-link"
                href="https://www.facebook.com/bodybyimransupplements"
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </a>
              <a
                onClick={handleShowNavBar}
                className="social-link"
                href="https://www.instagram.com/bodybyimran/?hl=en"
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>
              <a
                onClick={handleShowNavBar}
                className="social-link"
                href="https://www.tiktok.com/@bodybyimran?lang=en"
                target="_blank"
                rel="noreferrer"
              >
                Tiktok
              </a>
            </div>
          </div>
        </Fade>
      )}
      {showNavBar && (
        <Fade delay={300} direction="top">
          <div className="nav-links-container-dt">
            <Fade delay={200} cascade damping={0.2}>
              <ul className="nav-links-dt">
                <p className="menu-name-dt">Menu</p>
                <li className="nav-item-dt">
                  <a
                    onClick={handleShowNavBar}
                    href="#home"
                    className="nav-link-dt"
                  >
                    Home
                  </a>
                </li>
                <li className="nav-item-dt">
                  <a
                    onClick={handleShowNavBar}
                    href="#services"
                    className="nav-link-dt"
                  >
                    Services
                  </a>
                </li>
                <li className="nav-item-dt">
                  <a
                    onClick={handleShowNavBar}
                    href="#stores"
                    className="nav-link-dt"
                  >
                    Stores
                  </a>
                </li>
                <li className="nav-item-dt">
                  <a
                    onClick={handleShowNavBar}
                    href="#coaching"
                    className="nav-link-dt"
                  >
                    Coaching
                  </a>
                </li>
                <li className="nav-item-dt">
                  <a
                    onClick={handleShowNavBar}
                    href="#faqs"
                    className="nav-link-dt"
                  >
                    FAQs
                  </a>
                </li>
              </ul>
            </Fade>

            <div className="social-links-dt">
              <div className="actual-social-links-dt">
                <p className="socials-heading-dt">Socials</p>

                <a
                  onClick={handleShowNavBar}
                  className="social-link-dt"
                  href="https://www.facebook.com/bodybyimransupplements"
                  target="_blank"
                  rel="noreferrer"
                >
                  Facebook
                </a>
                <a
                  onClick={handleShowNavBar}
                  className="social-link-dt"
                  href="https://www.instagram.com/bodybyimran/?hl=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </a>
                <a
                  onClick={handleShowNavBar}
                  className="social-link-dt"
                  href="https://www.tiktok.com/@bodybyimran?lang=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  Tiktok
                </a>
              </div>
              <div className="get-in-touch">
                <p className="socials-heading-dt">Get in touch</p>
                <a
                  onClick={handleShowNavBar}
                  className="social-link-dt"
                  href="mailto:bodybyimranconsultations@gmail.com"
                >
                  bodybyimranconsultations@gmail.com
                </a>
              </div>
            </div>
          </div>
        </Fade>
      )}
    </div>
  );
}

export default Navigation;
